.issues {
  flex-shrink: 1;
  padding: 1rem;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.issues > ul {
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;

  padding: 1rem;

  gap: 1rem;

  overflow: scroll;
}
