.sidebar {
  flex: 0 0 20vw;
  border-right: 1px solid black;

  padding: 1rem;
  height: 100%;
}

.sidebar ul {
  list-style-type: none;

  overflow: scroll;
}

.sidebar ul li:hover {
  cursor: pointer;
  text-decoration: underline;
  background-color: gray;
  color: white;
}
