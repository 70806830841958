.App {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  min-height: 100vh;
}

.App > header {
  width: 100%;
  flex: 0 0 10vh;

  border-bottom: 1px solid black;

  margin: 0 auto;
  text-align: center;
}

.App > section {
  flex: 0 0 90vh;

  display: flex;
  flex-direction: row;
  justify-content: stretch;

  overflow: hidden;
}

.selected {
  background-color: black;
  color: white;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}
